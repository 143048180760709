import  {Box, Typography} from '@mui/material'
import {abrasionImage, abrasionText, deathrowImage, deathrowText, gamesDescription, horrorDungeonImage, horrorDungeonText, loadingTimeImage, loadingtimeText, playItchiBadge, pokibadge} from '../data/constants.js'
import Description from '../components/Description.js'



const GamesView = ({myRef}) =>{
    return(
        <Box sx={{display:'flex', flexDirection: 'column', alignItems: 'center', gap: 20, pb: 10}}>
                <GamnesTitleText myRef={myRef}/>
                <GameShowCase image={abrasionImage} text={abrasionText}/>
                <GameShowCase image={horrorDungeonImage} text={horrorDungeonText} link={"https://poki.com/en/g/horror-dungeon-3d"} badge={pokibadge}/>
                <GameShowCase image={deathrowImage} text={deathrowText} link={"https://studio-laaya.itch.io/death-row"} badge={playItchiBadge}/>
                <GameShowCase image={loadingTimeImage} text={loadingtimeText} link={"https://studio-laaya.itch.io/loading-time"} badge={playItchiBadge}/>
        </Box>
    )
}
const GamnesTitleText =({myRef})=>{
    return(
        <Box ref={myRef} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', gap: 20, pt: 20}}>
                <Typography variant='h4'>Games</Typography>
            <Box sx={{width: '100%', maxWidth: {xs: 300, sm: 750}}}>
                <Typography variant='b1'>{gamesDescription}</Typography>
            </Box>
        </Box>
    )
}

const GameShowCase = ({image, text, link, badge}) =>{
    return(
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 5}}>
            <a href={link} target="_blank" rel="noreferrer">
                <Box component='img'
                alt='logo'
                src={`${image}`}
                sx={{maxWidth: {xs: 350, sm: 450, md: 800}, maxHeight: {xs: 300, sm: 450, md: 500}, borderRadius: 4}}
                />
            </a>
            <Description text={text} />
            {badge && link != null && link.length > 0 ?
                <a href={link} target="_blank" rel="noreferrer">
                    <Box
                        component="img"
                        alt='logo'
                        src={`${badge}`}
                        sx={{maxWidth: 140, maxHeight: 140}}
                        />
                </a>
                : ""
            }
        </Box>
    )
}


export default GamesView