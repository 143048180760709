import  {Box, Typography, AppBar, Toolbar, Button} from '@mui/material'
import { ElevationScroll, LogoScroll } from '../components/ScrollinEffects'
import logo from '../images/logo.png'
const AppbarView = ({gamesRef}) =>{
    return(
        <Box>
            <ElevationScroll>
            <AppBar color='transparent' elevation={0}>
                <Toolbar>
                    <Box sx={{flexGrow: 1, ml: {xs: 0, sm: 10, md: 20}}}>
                    <LogoScroll>
                    <Box component='img'
                    alt='logo'
                    src={`${logo}`}
                    />
                    </LogoScroll>
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'row', gap: {sm: 5, md: 10}, mr: {sm: 1, md: 5}}}>
                    <Button onClick={() => {
                            // navigate('/')
                            window.scrollTo(0, 0)
                        }} sx={{color: 'white'}}>
                            <Typography variant='b1'>{"Home"}</Typography>
                        </Button>
                        <Button onClick={() => {
                            gamesRef.current?.scrollIntoView({
                                behavior: 'smooth'
                              })
                        }} sx={{color: 'white'}}>
                            <Typography variant='b1'>{"Games"}</Typography>
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>
            </ElevationScroll>
            <Toolbar/>
        </Box>
    )
}

export default AppbarView