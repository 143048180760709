import { Box, Divider, Stack, Typography } from "@mui/material"
import { contactEmail, contacts } from "../data/constants"

const Contact = () =>{
    return(
    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3, textAlign: 'center'}}>
        <Typography variant='b1'>{contactEmail}</Typography>
        <Divider variant='center' style={{width: '80%', backgroundColor: 'rgba(0,0,0, 0)'}}></Divider>
        <Stack direction="row" spacing={6}> 
            {contacts.map((contact, index) => (
                <ContactItem key={index} icon={contact[0]} url={contact[1]}/>
            ))}
        </Stack>
    </Box>
    ) 
}

const ContactItem = ({icon, url}) =>{
    return(
        <a href={url} target="_blank" rel="noreferrer">
            <Box
            component="img"
            alt='logo'
            src={`${icon}`}
            sx={{maxWidth: {xs: 25, sm: 55}, maxHeight: {xs: 25, sm: 55}}}
            />
        </a>
    )
}

export default Contact