import { createTheme } from "@mui/material";



const normalFontSize = 24;
const spacing = 3;


export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
    typography:{
        fontFamily: "'Quicksand', sans-serif",
        h1:{
            fontSize: normalFontSize*6,
            letterSpacing: spacing,
        },
        h2:{
            fontSize: normalFontSize*5,
            letterSpacing: spacing,
        },
        h3:{
            fontSize: normalFontSize*4,
            letterSpacing: spacing,
        },
        h4:{
            fontSize: normalFontSize*3,
            letterSpacing: spacing,
        },
        h5:{
            fontSize: normalFontSize*2,
            letterSpacing: spacing,
        },
        h6:{
            fontSize: normalFontSize*1.5,
            letterSpacing: spacing,
        },
        b1:{
            fontSize: normalFontSize,
            letterSpacing: spacing,
            fontWeight: 300,
        },
        b2:{
            fontSize: normalFontSize*0.6,
            letterSpacing: spacing,
        }
    }
} 
)