import oneWind from '../images/onewind.png'

import itchio from '../images/icons/itchio-textless-white.svg'
import discord from '../images/icons/discord-128.png'
// import facebook from '../images/icons/facebook-128.png'
import instagram from '../images/icons/instagram-128.png'
import twitter from '../images/icons/twitter-128.png'
//import tiktok from '../images/icons/tiktok-128.png'
import hdImage from '../images/games/web_horrordungeon.jpg'
import abrasion from '../images/games/web_abrasion.jpg'
import deathrow from '../images/games/web_deathrow.jpg'
import loadingTime from '../images/games/web_loadingtime.jpg'
import pokiBadgeDark from '../images/icons/poki-badge_dark.png'
import team from '../images/studio_laaya_team.jpg'
import ichioBadge from '../images/icons/itch.png'

// LANDINGPAGE
export const mainText = "We're a small indie team from Finland working on uniquely immersive games."
export const companyName = "Studio Laaya"
export const teamImage = team
export const teamText = "Our stakeholders"
export const playItchiBadge = ichioBadge

// GAMES
export const gamesDescription = "A collection of our finest works."
// WINDSKIP
export const windskipTitle = "Windskip"
export const windskipImage = oneWind;
export const windskipDescription = "Windskip. Calm and atmospheric platformer. (Canceled)"

// HORROR DUNGEON
export const horrorDungeonImage = hdImage
export const horrorDungeonText = "Can you find all 6 keys?"

// POKI
export const pokibadge = pokiBadgeDark

// ABRASION
export const abrasionImage = abrasion
export const abrasionText = "Coming 2024..."

// DEATHROW
export const deathrowImage = deathrow
export const deathrowText = "Your scheduled execution looms near. There is no escape, is there?"
// LOADING TIME
export const loadingTimeImage = loadingTime
export const  loadingtimeText = "Have you ever wondered what happens behind the loading screens?"
// CONTACT
export const contactEmail = "contact@studiolaaya.com"
export const contacts = [[discord, "https://bit.ly/s_LaayaDiscord"], 
                        //[facebook, "https://bit.ly/s_LaayaFacebook"], 
                        [instagram, "https://bit.ly/s_LaayaInstagram"], 
                        [twitter, "https://bit.ly/s_LaayaTwitter"] ,
                        [itchio, "https://studio-laaya.itch.io/"]
                        //[tiktok, "https://bit.ly/s_LaayaTwitter"]
                    ]