import  {Box, Typography} from '@mui/material'
import { LogoFrontScroll } from '../components/ScrollinEffects.js'
import {mainText, teamImage, teamText} from '../data/constants.js'
import frontImage from '../images/frontLogo.png'
import Contact from '../components/Contact.js'
import Description from '../components/Description.js'
import { useRef } from 'react'
import AppbarView from './AppbarView.js'
import GamesView from './GamesView.js'



const HomeView = () => {
    const myRef = useRef(null);
    return(
        <>
        <AppbarView gamesRef={myRef}/>
        <Box sx={{display:'flex', flexDirection: 'column', alignItems: 'center', gap: {xs: 25, sm: 30, md: 50}, pb: {xs: 5, sm: 10}}}>    
            <TitleText/>
            <Team/>
            <GamesView myRef={myRef}/>
            <Contact/>
        </Box>
        </>
    )
}
const TitleText =()=>{
    return(
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 20, textAlign: 'center', pt: {xs: 20, sm: 0}}}>
            <LogoFrontScroll>
            <Box component='img'
                alt='logo'
                src={`${frontImage}`}
                />
            </LogoFrontScroll>
            <Box sx={{width: '100%', maxWidth: {xs: 300, sm: 750}}}>
                <Typography variant='b1' align="center">{mainText}</Typography>
            </Box>
        </Box>
    )
}

const Team = () => {
    return(
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 5}}>
                    <Box component='img'
                    alt='logo'
                    src={`${teamImage}`}
                    sx={{maxWidth: {xs: 350, sm: 450, md: 800}, maxHeight: {xs: 300, sm: 450, md: 500}, borderRadius: 4}}
                    />
                <Description text={teamText} />
            </Box>
    )
}

export default HomeView