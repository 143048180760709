import { useScrollTrigger } from '@mui/material';
import React from 'react';

export const ElevationScroll = (props) =>{
    const {children} = props
    //const theme = useTheme();
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    })
    return React.cloneElement(children, {
        sx: trigger
          ? {
              bgcolor: 'rgba(0,0,0,0.3)',
                'transitionDuration': '500ms',
                'transitionProperty':
                'padding-top, padding-bottom, background-color',
                'transitionTimingFunction': 'ease-in-out',
                pt: 2,
                pb: 2,
            }
          : {
              bgcolor: 'transparent',
                'transitionDuration': '500ms',
                'transitionProperty':
                'padding-top, padding-bottom, background-color',
                'transitionTimingFunction': 'ease-in-out',
                pt: 5,
                pb: 5,
            },
      });
    }

    export const LogoFrontScroll = (props) =>{
        const {children} = props
        //const theme = useTheme();
        const trigger = useScrollTrigger({
            disableHysteresis: true,
            threshold: 0,
        })
        return React.cloneElement(children, {
            sx: trigger
              ? {
                opacity: 0,
                'transitionDuration': '500ms',
                'transitionProperty': 'opacity',
                'transitionTimingFunction': 'ease-in-out',
                maxHeight: 300,
                maxWidth: 300,
                }
              : {
                opacity: 0.9,
                'transitionDuration': '500ms',
                'transitionProperty': 'opacity',
                'transitionTimingFunction': 'ease-in-out',
                maxHeight: 300,
                maxWidth: 300,
                },
          });
        }

        export const LogoScroll = (props) =>{
          const {children} = props
          //const theme = useTheme();
          const trigger = useScrollTrigger({
              disableHysteresis: true,
              threshold: 0,
          })
          return React.cloneElement(children, {
              sx: trigger
                ? {
                  opacity: 0.9,
                  'transitionDuration': '500ms',
                  'transitionProperty': 'opacity',
                  'transitionTimingFunction': 'ease-in-out',
                  maxHeight: 70,
                  maxWidth: 70,
                  }
                : {
                  opacity: 0,
                  'transitionDuration': '500ms',
                  'transitionProperty': 'opacity',
                  'transitionTimingFunction': 'ease-in-out',
                  maxHeight: 70,
                  maxWidth: 70,
                  },
            });
          }