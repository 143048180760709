import { useTheme } from "@emotion/react";
import { Box, Divider, Typography, useMediaQuery } from "@mui/material"



const Description = ({text}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return(
    <Box sx={{width: '100%', maxWidth: {xs: 200, sm: 750}, textAlign: 'center'}}>
                    {isMobile || text.length > 30 ? 
                    <Typography variant='b2'>{text}</Typography>
                    :
                    <Divider variant='center' style={{width: {xs: '0%', sm: '80%'}, backgroundColor: 'rgba(0,0,0, 0)'}}>
                    <Typography variant='b2'>{text}</Typography>
                    </Divider>
                }
                </Box>
    )

}

export default Description