import 'react-router-dom'
import HomeView from './views/HomeView';
import {Box, CssBaseline, ThemeProvider} from '@mui/material'
import { darkTheme } from './theme/theme';
import bgImage from './images/background.png'
const App = () => {
  return (
    <Box sx={{  backgroundImage: `url(${bgImage})`, backgroundAttachment: 'fixed', backgroundRepeat: 'fixed', backgroundSize: '100% 100%'}}>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline/>
            <HomeView/>
        </ThemeProvider>
      </Box>
  );
}
export default App;
